import React       from 'react';
import styled      from '@emotion/styled';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import HeaderImage   from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import SEO           from '@interness/web-core/src/components/modules/SEO/SEO';
import CTASection    from '@interness/web-core/src/components/modules/CTASection/CTASection';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';
import Lightbox      from '@interness/web-core/src/components/media/Lightbox/Lightbox';

import { menu } from '../../data/service-menu';

const J = styled.p`
  text-align: justify;
`;

const UmarbeitungPage = (props) => {
  const galleryImages = props.data.gallery;
  return (
    <>
      <SEO title={'Umarbeitung'}/>
      <Subnavigation menu={menu} menuTitle='Atelier Service'/>
      <HeaderImage>
        <Img fluid={props.data.headerImages.media[2].file.localFile.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Heading>Schmuck-Umarbeitungen</Heading>
        <J>Wir arbeiten Ihre Schmuckstücke nach Ihren Vorstellungen um. Ein geerbter Ring, der Ihnen vom Stil her
          nicht gefällt, kann zu einem Anhänger im modernen Design umgestaltet werden, die Perle von einem Ohrring
          in
          einem ganz anderen Licht auf einem Ring erscheinen. Der Stil des Schmuckstücks kann erhalten bleiben oder
          der Charakter komplett verändert werden. Möglich ist alles von kleineren Umarbeitungen bis zum
          Einschmelzen
          und komplett neu Fertigen von Schmuckstücken. Wir beraten Sie bei der Umgestaltung, entwerfen Skizzen nach
          Ihren Vorstellungen und erstellen schließlich Ihr Unikat. Wählen Sie aus unseren Diamanten,
          Farbedelsteinen
          und Perlen die schönsten aus, und wir ergänzen Ihr Traumstück gern damit.</J>
        <h3>Galerie</h3>
        <Lightbox images={galleryImages.images} thumbnails={galleryImages.thumbnails} fromApi/>
        <Spacer/>
      </Wrapper>
      <CTASection/>
    </>
  )
};

export default UmarbeitungPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "goldschmiede-headers"}) {
            ...MediaCollectionHeaderImages
        }
        gallery: directusMediaCollection(name: {eq: "umarbeitung"}) {
            ...LightboxGalleryGrid
        }
    }
`;